.roles-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  background: var(--light-mode-bg);
}

.header-section {
  height: 7%;
  color: var(--dark-mode-txt-color);
  width: 100%;
  top: 0;
  position: absolute;
  background: var(--light-mode-bg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 8px 2px rgb(203, 203, 203);
}

.logo-anchor {
  text-decoration: none;
  width: fit-content;
  padding: 0 5px;
}

.logo-anchor img {
  width: 100px;
}

.header-actions {
  background: none;
  color: var(--light-mode-txt-color);
  padding: 5px 10px;
  border-radius: 50px;
  margin: 0 0 0 10px;
  font-size: 13px;
}

.header-actions:hover,
.header-actions:active {
  background: var(--dark-mode-bg);
  filter: blur(50);
  color: var(--dark-mode-txt-color);
  font-weight: 500;
}

.jobs-section {
  width: 100%;
  height: 75%;
  margin: 5rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.section-title {
  height: 10%;
  width: 100%;
}

.content-section {
  width: 100%;
  height: 95%;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 59% 39%;
  column-gap: 0.5rem;
  justify-content: flex-start;
  align-items: flex-start;
}

.job-list{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 0.2rem;
  width: 100%;
}
.job-list h4, .job-detail h4{
  margin: 0 0 0.4rem 0;
}

.job-item{
  width: 90%;
  margin: 0 0 0.3rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0.5rem 0.5rem;
  box-shadow:  2px 1px 1px 5px rgba(217, 217, 217, 0.048);
  border: 0.1rem solid rgba(246, 246, 246, 0.789);
  border-radius: 20px;
}

.job-item:hover{
  background: rgba(248, 248, 248, 0.796);
  cursor: pointer;

}

.job-item .title{
  font-weight: 700;
  font-size: var(--font-size-normal);
  width: 400px;
}
.job-item .department{
  font-size: var(--font-size-normal);
  width: 200px;
}
.job-item .location{
  font-size: var(--font-size-normal);
  width: 100px;
}
.job-item .indicator{
  font-weight: 600;
}

.show-details{
  background: none;
}

.job-detail{
  padding: 0 0.2rem;
  box-shadow:  2px 1px 1px 5px rgb(255, 255, 255);
  border: 0.1rem solid rgba(246, 246, 246, 0.789);
  border-radius: 20px;
  box-shadow:  2px 1px 1px 5px rgba(217, 217, 217, 0.048);

}

.illustration{
  padding: 0 0.2rem;
  width: 98%;
  height: auto;
}

.illustration img{
  position: fixed;
  top: 10%;
  width: 700px;
  height: 700px;
}

.footer-section {
  height: 7%;
  color: var(--light-mode-txt-color);
  width: 100%;
  bottom: 0;
  position: absolute;
  background: var(--light-mode-bg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 8px 1px 8px 2px rgb(216, 216, 216);
}

.terms-privacy,
.copy-right-holder,
.socials {
  font-size: 13px;
  padding: 0 5px;
}

.socials a{
  margin: 0 0 0 10px;
}

.phone-ico{
  color: #000;
}

.mail-ico{
  color: #E0AA3E;
  /* color: linear-gradient( #AE6625 50%,#E0AA3E 50%); */

}
.whatsapp-ico{
  color: #25D366;
  /* color: linear-gradient( #128C7E 50%, #25D366 50%); */
}

.facebook-ico{
  color: #4267B2;
  /* color: linear-gradient( #6CC1E3 50%, #4995BE 50%); */
}

.instagram-ico{
  color: #E1306C;
}

.twitter-ico{
  color: #1DA1F2;
}