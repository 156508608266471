.landing-container-lightmode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background: var(--light-mode-bg);
}

.landing-container-darkmode {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background: var(--dark-mode-bg);
}

.top-section {
  width: 96%;
  position: absolute;
  top: 0;
  padding-top: 10px;
}

.left-section {
  width: max-content;
  float: left;
  text-align: left;
  /* margin: 0 565px 0 0; */
}

.left-icon img {
  width: 48px;
  height: auto;
}

.right-section {
  width: max-content;
  float: right;
  text-align: right;
}

.right-section > button {
  margin: 0;
}

.right-section > button {
  transition: 0.3s ease-in-out;
  /* transform: scale(1.01); */
}

.right-section .right-icon-lightmode {
  margin: 0;
  padding: 0;

  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: auto;
  padding: 5px 10px;
  outline: none;
  border: none;

  background: var(--light-mode-bg);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  cursor: pointer;
}

.right-section .right-icon-lightmode img {
  width: 38px;
  height: 38px;
}

.right-section .right-icon-lightmode:hover {
  background: var(--dark-mode-bg);
}

.right-section .right-icon-lightmode:hover > img {
  filter: invert(100);
}

.right-section .right-icon-darkmode {
  margin: 0;
  padding: 0;

  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 74px;
  height: auto;
  padding: 5px 10px;
  outline: none;
  border: none;

  background: var(--light-mode-bg);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
  cursor: pointer;
}

.right-section .right-icon-darkmode:hover {
  filter: brightness(1.01);
}

.right-section .right-icon-darkmode img {
  width: 38px;
  height: 38px;
}

.right-section .right-icon-darkmode:hover > img {
  filter: brightness(1.2);
}

.middle-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.rocket {
  display: flex;
  margin: -60px 0 60px 500px;
}

.rocket img {
  width: 56px;
  height: auto;
}

.logo-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.organization-division img {
  margin: 25px 0 0 0;
  width: 56px;
  height: auto;
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 50px 0 50px;
}

.logo-container img {
  width: 472px;
  height: auto;
}

.slogan {
  display: flex;
  align-items: center;
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.005em;
}

.slogan-lightmode {
  color: var(--light-mode-txt-color);
}

.slogan-darkmode {
  color: var(--dark-mode-txt-color);
}

.bottom-section {
  width: 97%;
  height: max-content;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
}

.socials-section {
  width: 70%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 20px 0;
}

.socials-section a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 174.05px;
  height: 53px;

  box-sizing: border-box;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  /* or 114% */

  text-align: center;
  letter-spacing: 0.005em;
}

.socials-section a:focus {
  filter: brightness(1.1);
}

.ml,
.fb,
.ins,
.twt,
.li > img {
  width: 31px;
  height: 29px;
}

.li > img {
  filter: grayscale(100%);
  filter: brightness(10.5);
}
.ml,
.ins {
  color: #000;
  background-color: #fff;
}

.fb,
.twt,
.li {
  border-radius: 25px;
  color: #fff;
}

.ml {
  border: 1px solid #003e7b;
}

.fb {
  background: #105da1;
  border: 0.5px solid #115b9d;
}

.li {
  background: #003a6d;
  border: 0.5px solid #003a6d;
}

.ins {
  border: 1px solid #ff3d00;
}

.twt {
  background: #1791e8;
  border: 0.5px solid #1791e8;
}

.like {
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  width: 8%;
  /* margin: ; */
}

.like-btn {
  width: 38px;
  height: 32px;
  background: transparent;
  border: none;
  outline: none;
  /* position: absolute; */
  /* margin: -50px 0 0 150px; */
  cursor: pointer;
}

.like-btn:hover {
  transform: scale(1.05);
}

.like-btn:active {
  transform: rotateZ(90);
}

.like-btn:active {
  transform: rotate3d(180);
}

.like-btn:focus {
  transform: scale(1.2);
  transform: rotateX(180);
}

/*Mobile responsive styles*/
@media screen and (min-width: 280px) and (max-width: 416px) {
  body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
    width: 100%;
  }

  .landing-container-lightmode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 100vh;
    background: var(--light-mode-bg);
  }

  .landing-container-darkmode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 100vh;
    background: var(--dark-mode-bg);
  }

  /*Page top section*/
  .top-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 10px;
    padding: 0.2rem;
  }

  .left-section {
    margin: 0 0 0 0;
  }

  .left-icon img {
    width: 32px;
    height: auto;
  }

  .right-section {
    margin: 0 0 0 0;
  }

  .right-section .right-icon-lightmode img {
    width: 26px;
    height: 26px;
  }
  .right-section .right-icon-darkmode img {
    width: 26px;
    height: 26px;
  }

  /*Middle / logo section*/
  .rocket {
    display: flex;
    margin: -60px 0 60px 170px;
  }

  .rocket img {
    width: 32px;
    height: auto;
  }

  .logo-group {
    width: 100%;
  }

  .organization-division img {
    width: 32px;
    height: auto;
  }

  .logo-container {
    margin: 0 10px;
  }

  .logo-container img {
    width: 220px;
  }

  .slogan {
    font-size: 13px;
  }

  /*Bottom/ footer section*/
  .socials-section a {
    width: 36px;
    height: 22px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.001em;
    border: none;
    padding: 18px 0;
    /* background: none; */
  }

  .socials-section a > span {
    display: none;
  }

  .ml,
  .fb,
  .ins,
  .twt > img {
    width: 20px;
    height: 18px;
  }

  .ml,
  .ins {
    background: none;
  }

  .like {
    font-size: 10px;
    width: 8%;
    /* margin: ; */
  }

  .like-btn {
    width: 28px;
    height: 22px;
    margin: 0 5px 0 0;
  }

  .like-btn img {
    width: 28px;
    height: 22px;
  }
}

/*Larger phones and tablets responsive styles*/
@media screen and (min-width: 420px) and (max-width: 1080px) {
  body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
    width: 100%;
  }

  .landing-container-lightmode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 100vh;
    background: var(--light-mode-bg);
  }

  .landing-container-darkmode {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100vw;
    height: 100vh;
    background: var(--dark-mode-bg);
  }

  /*Page top section*/
  .top-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 10px;
    padding: 0.2rem;
  }

  .left-section {
    margin: 0 0 0 0;
  }

  .left-icon img {
    width: 36px;
    height: auto;
  }

  .right-section {
    margin: 0 0 0 0;
  }

  .right-section .right-icon-lightmode img {
    width: 30px;
    height: 30px;
  }
  .right-section .right-icon-darkmode img {
    width: 30px;
    height: 30px;
  }

  /*Middle / logo section*/
  .rocket {
    display: flex;
    margin: -60px 0 60px 380px;
  }

  .rocket img {
    width: 56px;
    height: auto;
  }

  .logo-group {
    width: 100%;
  }

  .organization-division img {
    width: 56px;
    height: auto;
  }

  .logo-container {
    margin: 0 10px;
  }

  .logo-container img {
    width: 380px;
  }

  .slogan {
    font-size: 18px;
  }

  .socials-section a {
    width: 50px;
    height: 32px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.001em;
    border: none;
    padding: 20px 0;
    /* background: none; */
  }

  .socials-section a > span {
    display: none;
  }

  .ml,
  .fb,
  .ins,
  .twt > img {
    width: 36px;
    height: 30px;
  }

  .ml,
  .ins {
    background: none;
  }

  .like {
    font-size: 14px;
    width: 8%;
    /* margin: ; */
  }

  .like-btn {
    width: 34px;
    height: 28px;
    margin: 0 5px 0 0;
  }

  .like-btn img {
    width: 34px;
    height: 28px;
  }
}
