
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --dark-mode-bg: #3f3e3e;
  --light-mode-bg: #fff;
  --blacked-out-bg: #000000;
  --dark-mode-txt-color: #fff;
  --light-mode-txt-color: #000;

  --font-size-small: 12px;
  --font-size-medium: 13px;
  --font-size-normal: 14px;
  --font-size-large: 15px;
}

* {
  transition: 0.3s;
  scroll-behavior: smooth;
}
h1, h2, h3, h4, h5, h6{
  margin: 0;
}


body {
  margin: 0;
  font-family: Inter;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max-content;
  width: 100vw;
}

button{
  border: none;
  outline: none;
  cursor: pointer;
}

a{
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}